<template>
    <div class="internal-sections-table licenseRequiments-main">
        <el-row :gutter="20">
            <el-col :span="24">
                <div class="d-flexk a-i-center">
                    <div class="title-internal-block">Faoliyat manziliga tegishlik ixtisosliklar</div>
                </div>
            </el-col>
        </el-row>

        <el-row class="bor-bottom-d-s" :gutter="20" v-for="(specialization, index) in app_specializations" :key="'specialization-'+index">
            <el-col :span="6">
                <div class="text-t-e">
                    Ixtisoslik
                </div>
            </el-col>
            <el-col :span="18">
                <div class="text-t-e text-right">
                   {{ specialization.name }}
                </div>
            </el-col>
        </el-row> 
    </div>
</template>
<script>
export default {
    props: {
       app_specializations: {
        type: Array,
        required: true
       } 
    },
    data() {
        return {
           
        };
    },
};
</script>
