<template>
    <div class="internal-sections-table licenseRequiments-main">
        <el-row :gutter="20">
            <el-col :span="24">
                <div class="d-flexk a-i-center">
                    <div class="title-internal-block">Tibbiy uskunalar</div>
                </div>
            </el-col>
        </el-row>

        <el-row class="bor-bottom-d-s" :gutter="20">
            <el-col :span="10">
                <div class="text-t-e">
                    Uskuna nomi
                </div>
            </el-col>
            <el-col :span="8">
                <div class="text-t-e">
                    Uskuna turi
                </div>
            </el-col>
            <el-col :span="5">
                <div class="text-t-e">
                    Soni
                </div>
            </el-col>
            <el-col :span="1">
                <div class="text-t-e">
                    <el-button
                        icon="el-icon-view"
                        class="mini-style2 mt10"
                        @click="centerDialogVisible = true"
                    >
                    </el-button>
                </div>
            </el-col>
        </el-row>
        
        <el-row class="bor-bottom-d-s" :gutter="20" v-for="(item, index) in list" :key="index">
            <el-col :span="10">
                <div class="text-t-e">
                  {{ item.name }}
                </div>
            </el-col>
            <el-col :span="8">
                <div class="text-t-e">
                    {{ item.type_name }}
                </div>
            </el-col>
            <el-col :span="5">
                <div class="text-t-e">
                    <div class="warning-bg">{{ item.count }}</div>
                </div>
            </el-col>
            <el-col :span="1">
                <div class="text-t-e">
                    <router-link :to="{ name: 'medicalEquipments' }">
                        <el-button
                            icon="el-icon-top-right"
                            class="mini-style2 mt10"
                            @click="centerDialogVisible = true"
                        >
                        </el-button>
                    </router-link>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    props: {
        list: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
           
        };
    },
};
</script>
