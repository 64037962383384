import { render, staticRenderFns } from "./organizationInformation.vue?vue&type=template&id=d90503a4&"
import script from "./organizationInformation.vue?vue&type=script&lang=js&"
export * from "./organizationInformation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports