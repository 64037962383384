<template>
    <div class="internal-sections-table licenseRequiments-main">
        <el-row :gutter="20">
            <el-col :span="24">
                <div class="d-flexk a-i-center">
                    <div class="title-internal-block">Faoliyat Manzili</div>
                </div>
            </el-col>
        </el-row>

        <el-row class="bor-bottom-d-s" :gutter="20">
            <el-col :span="6">
                <div class="text-t-e">
                    Hudud
                </div>
            </el-col>
            <el-col :span="18">
                <div class="text-t-e text-right">
                    {{address.region ? address.region.region_name : ''}}
                </div>
            </el-col>
        </el-row> 

        <el-row class="bor-bottom-d-s" :gutter="20">
            <el-col :span="6">
                <div class="text-t-e">
                    Tuman (shahar)
                </div>
            </el-col>
            <el-col :span="18">
                <div class="text-t-e text-right">
                    {{address.city_district ? address.city_district.name : ''}}
                </div>
            </el-col>
        </el-row> 

        <el-row class="bor-bottom-d-s" :gutter="20">
            <el-col :span="6">
                <div class="text-t-e">
                    Manzili
                </div>
            </el-col>
            <el-col :span="18">
                <div class="text-t-e text-right">
                    {{address.address}}
                </div>
            </el-col>
        </el-row>

        <el-row class="bor-bottom-d-s" :gutter="20">
            <el-col :span="6">
                <div class="text-t-e">
                    Davlat ro'yxatidan o'tgan sana
                </div>
            </el-col>
            <el-col :span="18">
                <div class="text-t-e text-right">
                    <div class="success-bg"> {{ address.time_of_registered }} </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    props: {
        address: {
            type: Object,
            required: true
        }
    },
    //  watch: {
    //     $props: {
    //         handler: function () {
    //             console.log('address', this.address);
    //         },
    //         deep: true,
    //     }
    // },
    data() {
        return {
           
        };
    },
};
</script>
