<template>
    <div>
        <div class="internal-sections-table licenseRequiments-main">
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="d-flexk a-i-center">
                        <div class="title-internal-block">Vazirlik tamonidan beriladigan xulosalar</div>
                    </div>
                </el-col>
            </el-row>

            <div class="mt-30">
                <el-row class="bor-bottom-d-s " :gutter="20" v-for="(conclusion, index) in app_conclusions" :key="'conclusion-'+index">
                    <el-col :span="12">
                        <div class="text-t-e">
                            {{conclusion.user ? conclusion.user.surname : ''}} {{conclusion.user ? conclusion.user.name : ''}} 
                            {{conclusion.user ? conclusion.user.first_name : ''}}
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="text-t-e">
                            {{conclusion.created_at}}
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="text-t-e text-right">
                            <div>
                                <span class="primary-bg" v-if="conclusion.app_condition == 2">Ko'rib chiqish jarayonida</span>
                                <span class="danger-bg" v-else-if="conclusion.app_condition == 3">Rad etildi</span>
                                <span class="success-bg" v-else-if="conclusion.app_condition == 5">Muvaffaqiyatli</span>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4" class="text-right">
                        <div class="success-bg conclusion" @click="showConclusion(conclusion)">
                            Xulosa <i class="el-icon-view"></i>
                        </div>
                    </el-col>
                </el-row> 
            </div>
        </div>
          <div class="my-modal-big">
            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="drawerConclusion"
                :wrapperClosable="true"
                size="70%"
                ref="drawerConclusion"
            >
                <show-conclusion
                    ref="drawerConclusionChild"
                    drawer="drawerConclusion"
                    :selectedItem="selectedConclusion"
                ></show-conclusion>
            </el-drawer>
        </div>
    </div>
</template>
<script>
import showConclusion from './dialog/showConclusion';
export default {
    props: {
        app_conclusions: {
            type: Array,
            required: true
        }
    },
    components: {showConclusion},
    data() {
        return {
            drawerConclusion: false,
            selectedConclusion: {}
        };
    },
    methods: {
        showConclusion(item){
            this.drawerConclusion = true;
            this.selectedConclusion = item;
        },
        closeDrawer(drawer){
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
    }
};
</script>
<style>
.mt-30{
    margin-top: 30px;
}
.conclusion{
    cursor: pointer;
}
</style>
