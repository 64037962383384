<template>
    <div>
        <div class="modal-header">
            <div class="modal-title-my">
                   <h4>Fotosuratlar</h4>
            </div>
            <div>
                <el-button @click="close()" type="danger" plain>{{ $t('message.close') }}</el-button>
            </div>
        </div>
        <div class="my-click">
            <div class="fayil-full another_certificate" v-for="(another, index) in anothers" :key="'anothers-'+index">
                <div>
                    <h2>Sertifikat nomi: {{ another.name ? another.name : '' }}</h2>
                </div>
                <div class="another_certificate_item">
                    <div class="fayil-itme" v-for="(image, index) in another.albums" :key="index">
                        <div class="itme-disct-doc">
                            <div class="itme-disct-right">
                                <img src="./../../../../../../public/img/file.png"/> 
                                <el-button
                                    type="primary" plain
                                    icon="el-icon-download"
                                    class="mini-style2 mt10"
                                    size="mini"
                                    @click="setData(image)"
                                >
                                </el-button>
                            </div>
                        </div>
                        <div class="name-distc">Fayil: <b>{{ image.original_name ? image.original_name : '' }}</b></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: ['anothers'],
    methods: {
        close() {                    
            this.parent().closeDrawer(this.drawer);        
        },
        parent() {
            return this.$parent;
        },
        setData(data){
            this.$emit('download-file', data);
        }
    }
}
</script>
<style>
.another_certificate{
    display: flex;
    flex-direction: column;
}
.another_certificate_item{
    display: flex;
    flex-direction: row;
    justify-content: start;
}
</style>
