<template>
    <div>
        <div class="modal-header">
            <div class="modal-title-my">
                <div class="title-internal-block font-1">Xulosa</div>
            </div>
            <div>
                <el-button type="warning" size="medium" @click="close()" plain>
                    Yopish
                </el-button>
            </div>
        </div>
        <div class="modal-content">
            <div class="card-body p30">
                <div class="internal-sections-table licenseRequiments-main rez-khan">
                   <span v-html="selectedItem.content"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import drawer from '@/utils/mixins/drawer';
export default {
    mixins: [drawer],
    props: {
        selectedItem: {
            type: Object,
            required: true
        }
    },
}
</script>

<style>

</style>