<template>
    <div class="internal-sections-table licenseRequiments-main">
        <el-row :gutter="20">
            <el-col :span="24">
                <div class="d-flexk a-i-center">
                    <div class="title-internal-block">Xujjatlar</div>
                </div>
            </el-col>
        </el-row>

        <el-row class="bor-bottom-d-s" :gutter="20">
            <el-col :span="11">
                <div class="text-t-e">
                    Xujjat turi
                </div>
            </el-col>
            <el-col :span="11">
                <div class="text-t-e">
                    Nomi
                </div>
            </el-col>
            <el-col :span="2">
                <div class="text-t-e">
                    Ko'rish
                </div>
            </el-col>
        </el-row>
        
        <el-row class="bor-bottom-d-s" :gutter="20" v-for="(item, index) in list" :key="index">
            <el-col :span="11">
                <div class="text-t-e">
                    <span v-if="item.type == 'building'">Bino inshoat ma'lumotlari</span>
                    <span v-else-if="item.type == 'technique'">Texnika ma'lumotlari</span>
                    <span v-else-if="item.type == 'equipment'">Uskuna ma'lumotlari</span>
                </div>
            </el-col>
            <el-col :span="11">
                <div class="text-t-e">
                    <div class="warning-bg">{{item.name ? item.name : ''}}</div>
                </div>
            </el-col>
            <el-col :span="2">
                <div class="text-t-e">
                    <el-button type="text" @click="showDocument(item)">Ko'rish <i class="el-icon-view"></i></el-button>
                </div>
            </el-col>
        </el-row>
         <div class="my-modal-big">
            <el-drawer
                :with-header="false"
                :append-to-body="true"
                title="Hujjatlar"
                :visible.sync="drawerShow"
                ref="drawerShow"
                @opened="drawerOpened('drawerShowChild')"
                @closed="drawerClosed('drawerShowChild')"
                size="80%">
                <show-documents 
                    :selectedItem="selectedItem"
                    ref="drawerShowChild"
                    drawer="drawerShow"
                ></show-documents>
            </el-drawer>
        </div>
    </div>
</template>
<script>
import showDocuments from './dialog/show-documents';
export default {
    props: {
        list: {
            type: Array,
            required: true
        }
    },
    components: {showDocuments},
    data() {
        return {
           drawerShow: false,
           selectedItem: null
        };
    },
    methods: {
        showDocument(item){
            this.drawerShow = true;
            this.selectedItem = item;
        },
        drawerOpened(ref) {
           if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened();
                }
           }
        },
        drawerClosed(ref) {
             if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].closed)) {
                    this.$refs[ref].closed();
                }
           }
        }
    }
};
</script>
