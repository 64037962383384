<template>
    <div class="internal-sections-table licenseRequiments-main">
        <el-row :gutter="20">
            <el-col :span="24">
                <div class="d-flexk a-i-center">
                    <div class="title-internal-block">Ariza tafsiloti</div>
                </div>
            </el-col>
        </el-row>
        
    </div>
</template>
<script>
export default {
    data() {
        return {
           
        };
    },
};
</script>
