<template>
    <div>
        <div class="modal-header">
            <div class="modal-title-my">
                   <h4>Fotosuratlar</h4>
            </div>
            <div>
                <el-button @click="close()" type="danger" plain>{{ $t('message.close') }}</el-button>
            </div>
        </div>
        <div class="my-click">
            <div class="fayil-full">
                <div class="fayil-itme" v-for="(image, index) in images" :key="index">
                    <div class="itme-disct-doc">
                          <div class="itme-disct-right">
                            <img src="./../../../../../../public/img/file.png"/> 
                            <el-button
                                type="primary" plain
                                icon="el-icon-download"
                                class="mini-style2 mt10"
                                size="mini"
                                @click="setData(image)"
                            >
                            </el-button>
                        </div>
                    </div>
                    <div class="name-distc">Fayil: <b>{{ image.original_name ? image.original_name : '' }}</b></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: ['images'],
    methods: {
        close() {                    
            this.parent().closeDrawer(this.drawer);        
        },
        parent() {
            return this.$parent;
        },
         setData(data){
            this.$emit('download-file', data);
        }
    }
}
</script>

