<template>
    <div>
        <div class="modal-header">
            <div class="modal-title-my">
                <h4>{{$t('message.nomi')}}: {{ selectedItem.name ? selectedItem.name : '' }}</h4>
            </div>
            <div>
                <el-button @click="close()" type="danger" plain>{{ $t('message.close') }}</el-button>
            </div>
        </div>
         <div class="bodal-body-my" v-if="isShow">
            <div v-show="isVisible">
               <div class="fayil-full">
                <div class="fayil-itme" v-for="(item, index) in building_informations" :key="'item-'+index">
                    <div class="itme-disct-doc">
                        <div class="itme-disct-right">
                            <img src="./../../../../../public/img/file.png" /> 
                            <el-button
                                type="primary" plain
                                icon="el-icon-download"
                                class="mini-style2 mt10"
                                size="mini"
                               @click="downloadFile(item)"
                            >
                            </el-button>
                        </div>
                    </div>
                    <div class="name-distc">Hujjat nomi: <b>{{ item.original_name }}</b></div>
                </div>
                </div>
            </div>
            <div v-show="!isVisible">
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-button @click="showImage = true">
                            <div class="disct">
                                <!-- <div class="son-d">25</div> ./../../../public/img/folder.png -->
                                <div class="disct-icon">
                                    <img src="./../../../../../public/img/folder.png" /> 
                                </div>
                                <div class="name-distc"><b>{{ title }} fotosurati</b></div>
                            </div>
                        </el-button>
                    </el-col>
                    <el-col :span="6">
                        <el-button @click="showSeriesNumber = true">
                            <div class="disct">
                                <!-- <div class="son-d">25</div> -->
                                <div class="disct-icon">
                                    <img src="./../../../../../public/img/folder.png" />
                                </div>
                                <div class="name-distc"><b>Seriya va raqami fotosurati</b></div>
                            </div>
                        </el-button>
                    </el-col>
                    <el-col :span="6">
                        <el-button @click="showPassport = true">
                            <div class="disct">
                                <!-- <div class="son-d">25</div> -->
                                <div class="disct-icon">
                                    <img src="./../../../../../public/img/folder.png" />
                                </div>
                                <div class="name-distc"><b>Passport fotosurati</b></div>
                            </div>
                        </el-button>
                    </el-col>
                    <el-col :span="6">
                        <el-button @click="showCertificate = true">
                            <div class="disct">
                                <!-- <div class="son-d">25</div> -->
                                <div class="disct-icon">
                                    <img src="./../../../../../public/img/folder.png" />
                                </div>
                                <div class="name-distc"><b>Sertifikat fotosurati</b></div>
                            </div>
                        </el-button>
                    </el-col>
                    <el-col :span="6">
                        <el-button @click="showOwnership = true">
                            <div class="disct">
                                <!-- <div class="son-d">25</div> -->
                                <div class="disct-icon">
                                    <img src="./../../../../../public/img/folder.png" />
                                </div>
                                <div class="name-distc"><b>Mulk egaligi fotosurati</b></div>
                            </div>
                        </el-button>
                    </el-col>
                    <el-col :span="6">
                        <el-button @click="showAnotherCertificate = true">
                            <div class="disct">
                                <!-- <div class="son-d">25</div> -->
                                <div class="disct-icon">
                                    <img src="./../../../../../public/img/folder.png" />
                                </div>
                                <div class="name-distc"><b>Boshqa sertifikat fotosurati</b></div>
                            </div>
                        </el-button>
                    </el-col>
                </el-row>
            </div>
        </div>
        <template>
            <!-- IMAGES -->
            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="showImage"
                size="80%"
                ref="drawerShow"
                @opened="drawerOpened('drawerShowChild')"
                >
                <crm-image
                    :images="images"
                    ref="drawerShowChild"
                    drawer="drawerShow"
                    @download-file="downloadFile"
                ></crm-image>
            </el-drawer>
            <!-- Series and Number Images-->
            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="showSeriesNumber"
                size="80%"
                ref="drawerShow"
                @opened="drawerOpened('drawerShowChild')"
                >
                <crm-series-number
                    :images="seriesNumber"
                    ref="drawerShowChild"
                    drawer="drawerShow"
                    @download-file="downloadFile"
                ></crm-series-number>
            </el-drawer>
            <!-- Passport Images -->
            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="showPassport"
                size="80%"
                ref="drawerShow"
                @opened="drawerOpened('drawerShowChild')"
                >
                <crm-passport
                    :images="passport"
                    ref="drawerShowChild"
                    drawer="drawerShow"
                    @download-file="downloadFile"
                ></crm-passport>
            </el-drawer>
            <!-- Certificate Images -->
            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="showCertificate"
                size="80%"
                ref="drawerShow"
                @opened="drawerOpened('drawerShowChild')"
                >
                <crm-certificate
                    :images="certificate"
                    ref="drawerShowChild"
                    drawer="drawerShow"
                    @download-file="downloadFile"
                ></crm-certificate>
            </el-drawer>
            <!-- Property Ownership Images -->
            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="showOwnership"
                size="80%"
                ref="drawerShow"
                @opened="drawerOpened('drawerShowChild')"
                >
                <crm-ownership
                    :images="ownerships"
                    ref="drawerShowChild"
                    drawer="drawerShow"
                    @download-file="downloadFile"
                ></crm-ownership>
            </el-drawer>
            <!-- Another Certificate -->
            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="showAnotherCertificate"
                size="80%"
                ref="drawerShow"
                @opened="drawerOpened('drawerShowChild')"
                >
                <crm-another-certificate
                    :anothers="another_certificate"
                    ref="drawerShowChild"
                    drawer="drawerShow"
                    @download-file="downloadFile"
                ></crm-another-certificate>
            </el-drawer>
        </template>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import _ from 'lodash';
import crmImage from './showComponents/images';
import crmSeriesNumber from './showComponents/series_and_number';
import crmPassport from './showComponents/passport_img';
import crmCertificate from './showComponents/certificate';
import crmOwnership from './showComponents/ownership';
import crmAnotherCertificate from './showComponents/anotherCertificate';
export default {
    props: {
        selectedItem: {
            type: Object,
            required: true
        },
        drawer:{
            type: String,
            required: true
        }
    },
    components: {crmImage, crmSeriesNumber, crmPassport, crmCertificate, crmOwnership, crmAnotherCertificate}, //
    data:() => ({
        isShow: false,
        isVisible: false,
        title: '',
        building_informations: [],
        showImage: false,
        images: [],
        showSeriesNumber:false,
        seriesNumber: [],
        showPassport: false,
        passport: [],
        showCertificate: false,
        certificate: [],
        showOwnership: false,
        ownerships: [],
        showAnotherCertificate: false,
        another_certificate: []
    }),
    methods: {
        ...mapActions({
            download_file: 'documents/downloadFile'
        }),
        opened(){
            if (this.selectedItem) {
                this.isShow = true;
                if (this.selectedItem.type == 'building') {
                    this.building_informations = _.map(this.selectedItem.b_files, item => {
                        return {
                            id: item.id,
                            document_type: 'clinic',
                            original_name: item.original_name
                        }
                    });
                    this.isVisible = true;
                }
                else {
                    this.title = (this.selectedItem.type == 'technique') ? 'Texnika' : 'Uskuna';
                    
                    if (this.selectedItem.type == 'technique') {
                        if (this.selectedItem.hasOwnProperty('technique_images')) {
                            this.images = this.selectedItem.technique_images.map(item => {
                                return {
                                    id: item.id,
                                    document_type: 'image',
                                    original_name: item.original_name
                                }
                            });
                        }
                    }
                    else {
                        if (this.selectedItem.hasOwnProperty('equipment_images')) {
                            this.images = this.selectedItem.equipment_images.map(item => {
                                return {
                                    id: item.id,
                                    document_type: 'image',
                                    original_name: item.original_name
                                }
                            });
                        }
                    }
                        
                    if (this.selectedItem.hasOwnProperty('series_and_number_images')) {
                        this.seriesNumber = this.selectedItem.series_and_number_images.map(item => {
                            return {
                                id: item.id,
                                document_type: 'series_and_number',
                                original_name: item.original_name
                            }
                        });
                    }

                    if (this.selectedItem.hasOwnProperty('passport_images')) {
                        this.passport = this.selectedItem.passport_images.map(item => {
                            return {
                                id: item.id,
                                document_type: 'passport',
                                original_name: item.original_name
                            }
                        });
                    }

                    if (this.selectedItem.hasOwnProperty('certificate_images')) {
                        this.certificate = this.selectedItem.certificate_images.map(item => {
                            return {
                                id: item.id,
                                document_type: 'certificate',
                                original_name: item.original_name
                            }
                        });
                    }

                    if (this.selectedItem.hasOwnProperty('ownership_images')) {
                        this.ownerships = this.selectedItem.ownership_images.map(item => {
                            return {
                                id: item.id,
                                document_type: 'ownership',
                                original_name: item.original_name
                            }
                        });
                    }

                    if (this.selectedItem.hasOwnProperty('another_certificates')){
                        this.another_certificate = this.selectedItem.another_certificates.map(item => {
                            return {
                                id: item.id,
                                name: item.name,
                                albums: _.map(item.another_images, elem => {
                                    return {
                                        id: elem.id,
                                        document_type: 'another_certificate',
                                        original_name: elem.original_name
                                    }
                                })
                            }
                        });
                    }
                }
            }
        },
        downloadFile(file){  
            let fileName = file.original_name;
            let query = {file_id: file.id, document_type: file.document_type};
            this.download_file(query).then((res) => {
                const file_url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/*'}));
                const file_link = document.createElement('a');
                file_link.href = file_url;
                file_link.setAttribute('download', fileName);
                document.body.appendChild(file_link);
                file_link.click();
            }).catch((err) => {
                console.log(err.response.data);
            });
                // let split_el = _.split(res.data.type, '/', 2);
                // let hasfileName = (fileName) ? fileName : (split_el[0] + '.' + split_el[1]); 
        },
        close() {                    
          this.$parent.closeDrawer(this.drawer);        
        },
        closed(){
            this.isShow = false; 
            this.isVisible = false;
            this.building_informations = [];   
            this.images = [];
            this.seriesNumber = [];
            this.passport = [];
            this.certificate = [];
            this.ownerships = [];
            this.another_certificate = [];
        },
        drawerOpened(ref) {
           if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened();
                }
           }
        },
    }
}
</script>

<style>
.itme-disct-right{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
</style>