<template>
    <div class="internal-sections-table licenseRequiments-main">
        <el-row :gutter="20">
            <el-col :span="24">
                <div class="d-flexk a-i-center">
                    <div class="title-internal-block">O'zgarishlar jurnali</div>
                </div>
            </el-col>
        </el-row>

        <el-row class="bor-bottom-d-s" :gutter="20">
            <el-col :span="6">
                <div class="text-t-e">
                    Foydalanuvchi
                </div>
            </el-col>
            <el-col :span="6">
                <div class="text-t-e">
                    Holat
                </div>
            </el-col>
            <el-col :span="6">
                <div class="text-t-e">
                    Eski holat
                </div>
            </el-col>
            <el-col :span="6">
                <div class="text-t-e">
                    Sana
                </div>
            </el-col>
        </el-row>
        
        <el-row class="bor-bottom-d-s" :gutter="20">
            <el-col :span="6">
                <div class="text-t-e">
                    ABDURAXMONOV ULUGBEK
                </div>
            </el-col>
            <el-col :span="6">
                <div class="text-t-e">
                    <div class="warning-bg"> Ko'rib chiqilmoqda </div>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="text-t-e">
                    <div class="warning-bg"> To'ldirish jarayonida </div>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="text-t-e">
                    04.10.2022 17:32
                </div>
            </el-col>
        </el-row>
        <el-row class="bor-bottom-d-s" :gutter="20">
            <el-col :span="6">
                <div class="text-t-e">
                    ABDURAXMONOV ULUGBEK
                </div>
            </el-col>
            <el-col :span="6">
                <div class="text-t-e">
                    <div class="warning-bg"> Ko'rib chiqilmoqda </div>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="text-t-e">
                    <div class="warning-bg"> To'ldirish jarayonida </div>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="text-t-e">
                    04.10.2022 17:32
                </div>
            </el-col>
        </el-row>
        <el-row class="bor-bottom-d-s" :gutter="20">
            <el-col :span="6">
                <div class="text-t-e">
                    ABDURAXMONOV ULUGBEK
                </div>
            </el-col>
            <el-col :span="6">
                <div class="text-t-e">
                    <div class="warning-bg"> Ko'rib chiqilmoqda </div>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="text-t-e">
                    <div class="primary-bg"> Тўланмаган </div>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="text-t-e">
                    04.10.2022 17:32
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
           
        };
    },
};
</script>
