<template>
    <div>
        <!-- <div class="internal-sections-table licenseRequiments-main">
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="d-flexk a-i-center">
                        <div class="title-internal-block">So'ralgan litsenziyalar</div>
                    </div>
                </el-col>
            </el-row>

            <el-row class="bor-bottom-d-s" :gutter="20">
                <el-col :span="6">
                    <div class="text-t-e">
                        Litsenziya
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="text-t-e">
                        Qo'shimchalar <span class="primary-bg"> 5ta</span> 
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="text-t-e text-right">
                        <div class="success-bg">
                            LitsenziyaLitsenziyaLitsenziyaLitsenziyaLitsenziyaLitsenziya1
                        </div>
                    </div>
                </el-col>
            </el-row> 
            <el-row class="bor-bottom-d-s" :gutter="20">
                <el-col :span="6">
                    <div class="text-t-e">
                        Litsenziya
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="text-t-e">
                        Qo'shimchalar <span class="primary-bg"> 5ta</span> 
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="text-t-e text-right">
                        <div class="success-bg">
                            LitsenziyaLitsenziyaLitsenziyaLitsenziyaLitsenziyaLitsenziya1
                        </div>
                    </div>
                </el-col>
            </el-row> 
        </div> -->

        <div class="internal-sections-table licenseRequiments-main">
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="d-flexk a-i-center">
                        <div class="title-internal-block">Ariza tafsiloti </div>
                    </div>
                </el-col>
            </el-row>

            <el-row class="bor-bottom-d-s" :gutter="20">
                <el-col :span="6">
                    <div class="text-t-e">
                        Xolat
                    </div>
                </el-col>
                <el-col :span="18">
                    <div class="text-t-e text-right">
                        <div :class="[detalis.condition_id == 1 ? 'success-bg' : 'warning-bg']"> 
                            <span v-if="detalis.condition_id == 1">Yangi</span>
                            <span v-else>Ko'rib chiqilmoqda</span>
                        </div>
                    </div>
                </el-col>
            </el-row> 
            
            <el-row class="bor-bottom-d-s" :gutter="20">
                <el-col :span="6">
                    <div class="text-t-e">
                        Ariza Raqami
                    </div>
                </el-col>
                <el-col :span="18">
                    <div class="text-t-e text-right">
                        {{detalis.application_number}}
                    </div>
                </el-col>
            </el-row> 

            <el-row class="bor-bottom-d-s" :gutter="20">
                <el-col :span="6">
                    <div class="text-t-e">
                        Ariza sanasi
                    </div>
                </el-col>
                <el-col :span="18">
                    <div class="text-t-e text-right">
                        {{detalis.created_at}}
                    </div>
                </el-col>
            </el-row> 

            <el-row class="bor-bottom-d-s" :gutter="20">
                <el-col :span="6">
                    <div class="text-t-e">
                        Ariza oxirgi ko'rib chiqilgan sanasi
                    </div>
                </el-col>
                <el-col :span="18">
                    <div class="text-t-e text-right">
                        {{detalis.last_created_at}}
                    </div>
                </el-col>
            </el-row> 

            <el-row class="bor-bottom-d-s" :gutter="20">
                <el-col :span="6">
                    <div class="text-t-e">
                        Xujjat turi
                    </div>
                </el-col>
                <el-col :span="18">
                    <div class="text-t-e text-right">
                        <div class="primary-bg"> {{detalis.document_type}}</div>
                    </div>
                </el-col>
            </el-row>

            <el-row class="bor-bottom-d-s" :gutter="20">
                <el-col :span="6">
                    <div class="text-t-e">
                        Kabinet turi
                    </div>
                </el-col>
                <el-col :span="18">
                    <div class="text-t-e text-right">
                        <div class="success-bg" v-if="detalis.cabinet_type"> Back office </div>
                        <div class="success-bg" v-else> Front office </div>
                    </div>
                </el-col>
            </el-row>

            <el-row class="bor-bottom-d-s" :gutter="20">
                <el-col :span="6">
                    <div class="text-t-e">
                        Ariza turi
                    </div>
                </el-col>
                <el-col :span="18">
                    <div class="text-t-e text-right">
                        <div class="success-bg" v-if="detalis.application_type"> Yangi </div>
                        <div class="success-bg" v-else> Қайта рўйҳат </div>
                    </div>
                </el-col>
            </el-row> 
            
        </div>

        
    </div>
</template>
<script>
export default {
    props: {
        detalis: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
           
        };
    },
};
</script>
<style>
.mt-30{
    margin-top: 30px;
}
</style>
