<template >
    <div>
        <div class="modal-header">
            <div class="modal-title-my">
                {{
                    $t("message.new_c", {
                        m: $t("message.roles"),
                    })
                }}
            </div>
            <div>
                <crm-store-update-close
                    :button_type="'store'"
                    :loading="loadingButton"
                    @c-submit="submit(true)"
                    @c-close="close()"
                ></crm-store-update-close>
            </div>
        </div>

        <div class="bodal-body-my">
            <el-form ref="form" status-icon :model="form" :rules="rules">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="Tekshiruv Xulosasi" prop="condition_id">
                            <el-select class="w-100" v-model="form.condition_id" placeholder="Tekshiruv Xulosasi">
                                <el-option-group
                                    v-for="group in options"
                                    :key="group.label"
                                    :label="group.label">
                                    <el-option
                                        v-for="item in group.options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-option-group>
                            </el-select>
                        </el-form-item>

                        <div class="Editor_widht documentation_content">
                            <Tinymce
                            :id="tinymceId"
                            ref="editor"
                            class="naw_Editor"
                            v-model="form.template_content"
                            :height="960"
                            />
                        </div>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <!-- end app-modal__body -->
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "../../../utils/mixins/form";
import Tinymce from "@/components/Tinymce";

    export default {
        components:{
            Tinymce
        },
        mixins: [drawer, form],
        props: {
            selectedItem: {
                type: Object,
            }
        },
        computed: {
            ...mapGetters({
                model: 'checkApplicationResult/model',
                rules: 'checkApplicationResult/rules'
            })
        },
        data() {
        return {
            tinymceId: 'check_application_id',
            options: [{
            label: 'Litsenziya beruvchi',
            options: [
                {
                    value: 2,
                    label: "Ko'rib chiqish jarayonida"
                }, 
                {
                    value: 3,
                    label: "Rad etildi"
                },
                {
                    value: 5,
                    label: "Muvaffaqiyatli"
                }

            ]
            }, {
            label: 'Litsenziya  oluvchi',
            options: [
                {
                    value: 'Chengdu',
                    label: "Qayta to'ldirish jarayonida"
                }, 
                {
                    value: 'Shenzhen',
                    label: "Tuzzatish jarayonida "
                }, 
                {
                    value: 'Guangzhou',
                    label: " To'lmagan "
                }, 
                {
                    value: 'Dalian',
                    label: 'Tasdiqlash jarayonida '
                },
                {
                    value: 'Dalianq ',
                    label: ' Kelishuv jarayonida '
                }
            ]
            }],
        }
        },
        methods: {
            ...mapActions({
                save: 'checkApplicationResult/store'
            }),
            afterOpen(){
                if (this.$refs['editor']){
                    if (_.isFunction(this.$refs['editor'].init)) {
                        this.$refs['editor'].init();
                    }
                }
                this.form = JSON.parse(JSON.stringify(this.model));
            },
            submit(close = true) {
                this.form.application_result_item_id = parseInt(this.selectedItem.id);
                this.$refs["form"].validate((valid) => {
                    if (valid) {
                        this.loadingButton = true;
                        this.save(this.form)
                            .then((res) => {
                                this.loadingButton = false;
                                this.$alert(res);
                                if (close) this.close();
                                this.$router.push({name: 'confirmAllApplications'});
                            })
                            .catch((err) => {
                                this.loadingButton = false;
                                this.$alert(err);
                            });
                    }
                });
            },
            setToTinyContent() {
                window.tinymce.get(this.tinymceId).setContent(this.form.template_content || "");
            },
            afterLeave(){
                this.form.template_content = "";
                this.setToTinyContent();
                window.tinymce.get(this.tinymceId).remove();
            }
        }
    }
  </script>