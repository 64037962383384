<template>
    <div class="internal-sections-table licenseRequiments-main">
        <el-row :gutter="20">
            <el-col :span="24">
                <div class="d-flexk a-i-center">
                    <div class="title-internal-block">Tashkilot malumotlari</div>
                </div>
            </el-col>
        </el-row>

        <el-row class="bor-bottom-d-s" :gutter="20">
            <el-col :span="6">
                <div class="text-t-e">
                    Tashkilot INN raqami
                </div>
            </el-col>
            <el-col :span="18">
                <div class="text-t-e text-right">
                     <div class="warning-bg"> {{ clinic.inn }}</div> <!-- 303466730 -->
                </div>
            </el-col>
        </el-row> 
        
        <el-row class="bor-bottom-d-s" :gutter="20">
            <el-col :span="6">
                <div class="text-t-e">
                    Tashkilot nomi
                </div>
            </el-col>
            <el-col :span="18">
                <div class="text-t-e text-right">
                    {{ clinic.legal_entity_name }}
                </div>
            </el-col>
        </el-row> 

        <el-row class="bor-bottom-d-s" :gutter="20">
            <el-col :span="6">
                <div class="text-t-e">
                    Hudud
                </div>
            </el-col>
            <el-col :span="18">
                <div class="text-t-e text-right">
                    {{ clinic.region ? clinic.region.region_name : ''}}
                </div>
            </el-col>
        </el-row> 

        <el-row class="bor-bottom-d-s" :gutter="20">
            <el-col :span="6">
                <div class="text-t-e">
                    Tuman (shahar)
                </div>
            </el-col>
            <el-col :span="18">
                <div class="text-t-e text-right">
                    {{ clinic.city_district ? clinic.city_district.name : '' }}
                </div>
            </el-col>
        </el-row> 

        <el-row class="bor-bottom-d-s" :gutter="20">
            <el-col :span="6">
                <div class="text-t-e">
                    Manzili
                </div>
            </el-col>
            <el-col :span="18">
                <div class="text-t-e text-right">
                   {{clinic.address}} 
                </div>
            </el-col>
        </el-row>

        <el-row class="bor-bottom-d-s" :gutter="20">
            <el-col :span="6">
                <div class="text-t-e">
                    Davlat ro'yxatidan o'tgan sana
                </div>
            </el-col>
            <el-col :span="18">
                <div class="text-t-e text-right">
                    <div class="success-bg"> {{clinic.time_of_registered}} </div>
                </div>
            </el-col>
        </el-row>

        <el-row class="bor-bottom-d-s" :gutter="20">
            <el-col :span="6">
                <div class="text-t-e">
                    Telefon raqami
                </div>
            </el-col>
            <el-col :span="18">
                <div class="text-t-e text-right">
                    {{ clinic.phone_number }}
                </div>
            </el-col>
        </el-row> 
        
    </div>
</template>
<script>
export default {
    props: {
        clinic: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
           
        };
    },
};
</script>
