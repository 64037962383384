<template>
    <div>
        <div class="internal-sections-top">
            <el-row :gutter="20">
                <el-col :span="18">
                    <div class="d-flexk a-i-center mb20">
                        <div class="title-internal-block font-1">{{$t('message.name_of_clinic')}}: {{ clinic_name }}</div>
                    </div>
                </el-col>
                <el-col :span="6" class="filter_table">
                    <el-button @click="showDrawer()">Tekshiruv natijasi</el-button>
                </el-col>
            </el-row>
            <el-breadcrumb style="margin-bottom: 20px">
                <el-breadcrumb-item>
                    <router-link :to="{ name: 'confirmAllApplications' }">
                        Arizalar
                    </router-link>
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    Yangi
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        
        <el-tabs tab-position="left" v-model="activeName">
            <el-tab-pane name="first">
                <span slot="label"> Ariza Holati Natijasi <i class="el-icon-s-flag" style="color: red"></i></span>
                <ApplicationStatusResult :information="app_information" :results="data" v-loading="loadingData"></ApplicationStatusResult>
            </el-tab-pane>
            <el-tab-pane  name="elleventh">
                <span slot="label"> Xulosalar <i class="el-icon-edit-outline" style="color: red"></i></span>
                <ApplicationConclusion :app_conclusions="conclusions" v-loading="loadingData"></ApplicationConclusion>
            </el-tab-pane>
              <el-tab-pane label="Ariza tafsiloti" name="second">
                <ApplicationDetails :detalis="app_information"></ApplicationDetails>
            </el-tab-pane>
            <el-tab-pane label="Tashkilot malumotlari" name="thrid">
                <OrganizationInformation :clinic="clinic"></OrganizationInformation>
            </el-tab-pane>
            <el-tab-pane label="Ixtisoslik" name="fourth">
                <Specialties :app_specializations="specializations" v-loading="loadingData"></Specialties>
            </el-tab-pane>
            <el-tab-pane label="Faoliyat Manzili" name="fiveth">
                <businessAddress :address="address"></businessAddress>
            </el-tab-pane>
            <el-tab-pane label="Tibbiy Texnikalar" name="sixth">
                <techniques :list="techniques" v-loading="loadingData"></techniques>
            </el-tab-pane>
            <el-tab-pane label="Tibbiy uskunalar" name="seventh">
                <equipments :list="equipments" v-loading="loadingData"></equipments>
            </el-tab-pane>
            <el-tab-pane label="O'zgarishlar jurnali" name="eighth">
                <logChanges></logChanges>
            </el-tab-pane>
            <el-tab-pane label="Xujjatlar" name="nineth">
                <documents :list="documents" v-loading="loadingData"></documents>
            </el-tab-pane>
            <el-tab-pane label="To'lov" name="tenth">
                <Employees></Employees>
            </el-tab-pane>
        </el-tabs> 
        
        <div class="my-modal-big">
            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="drawerCreate"
                :wrapperClosable="true"
                size="90%"
                ref="drawerCreate"
                @opened="drawerOpened('drawerCreateChild')"
                @closed="drawerClosed('drawerCreateChild')"
            >
                <crm-create
                    ref="drawerCreateChild"
                    drawer="drawerCreate"
                    :selectedItem="selectedItem"
                ></crm-create>
            </el-drawer>
        </div>
    </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex';
import ApplicationStatusResult from "./components/applicationStatusResult.vue";
import Employees from "./components/employees.vue";
import CrmCreate from "./components/crm-create";
import Specialties from "./components/specialties.vue";
import ApplicationDetails from "./components/applicationDetails.vue";
import OrganizationInformation from "./components/organizationInformation.vue";
import businessAddress from "./components/businessAddress.vue";
import techniques from "./components/techniques.vue";
import equipments from "./components/equipments.vue";
import logChanges from "./components/logChanges.vue";
import documents from "./components/documents.vue";
import ApplicationConclusion from "./components/applicationConclusion";
export default {
    name: "confirmList",
    components: {
        ApplicationStatusResult,
        Employees,
        CrmCreate,
        Specialties,
        ApplicationDetails,
        OrganizationInformation,
        businessAddress,
        logChanges,
        techniques,
        equipments,
        documents,
        ApplicationConclusion
    },
    data(){
        return {
            clinic_id: null,
            clinic_name: '',
            drawerCreate: false,
            sarcheBlocks: "",
            data: [],
            activeName: 'first',
            loadingData: false,
            app_information: {},
            clinic: {},
            address: {},
            techniques: [],
            equipments: [],
            documents: [],
            selectedItem: {},
            conclusions: [],
            specializations: [],
            app_result_id: null
        };
    },
    watch: {
        'activeName': {
            handler: function (name, oldName) { 
                switch (name) {
                    case 'first':
                        let debounce_func = _.debounce(this.fetchData, 500);
                        debounce_func();
                        this.techniques = [];
                        this.equipments = [];
                        this.documents = [];
                        this.conclusions = [];
                        this.specializations = [];
                        break;
                    case 'fourth':
                        let app_result_id = parseInt(this.$route.params.application_result_id); 
                        if (app_result_id && !this.loadingData) {
                            this.loadingData = true;
                            this.getSpecializations(app_result_id).then((res) => {
                                if (res.specializations) {
                                    this.loadingData = false;
                                    this.specializations = res.specializations;
                                }
                                this.data = [];
                                this.app_information = {};
                                this.clinic = {};
                                this.address = {};
                                this.techniques = [];
                                this.equipments = [];
                                this.documents = [];
                                this.conclusions = [];
                            }).catch((err) => {
                                this.loadingData = false; 
                                this.$alert(err.response.data);
                            })
                        }
                        break;
                    case 'sixth': 
                        if (this.clinic_id && !this.loadingData) {
                            this.loadingData = true;
                            this.getTechniquesForApplicationResult(this.clinic_id).then((res) => {
                                if (res.techniques) {
                                    this.loadingData = false;
                                    this.techniques = res.techniques;
                                }
                                this.data = [];
                                this.app_information = {};
                                this.clinic = {};
                                this.address = {};
                                this.equipments = [];
                                this.documents = [];
                                this.conclusions = [];
                                this.specializations = [];
                            });
                        }
                        break;
                    case 'seventh': 
                        if (this.clinic_id && !this.loadingData) {
                            this.loadingData = true;
                            this.getEquipmentsForApplicationResult(this.clinic_id).then((res) => {
                                if (res.equipments) {
                                    this.equipments = res.equipments;
                                    this.loadingData = false;
                                }
                                this.data = [];
                                this.app_information = {};
                                this.clinic = {};
                                this.address = {};
                                this.techniques = [];
                                this.documents = [];
                                this.conclusions = [];
                                this.specializations = [];
                            });
                        }
                        break;
                    case 'nineth':
                        if (this.clinic_id && !this.loadingData) {
                            this.loadingData = true;
                            this.getAllDocumentsOfClinic(this.clinic_id).then((res) => {
                                if (res.documents) {
                                    this.documents = res.documents;
                                    this.loadingData = false;
                                }
                                this.data = [];
                                this.app_information = {};
                                this.clinic = {};
                                this.address = {};
                                this.techniques = [];
                                this.equipments = [];
                                this.conclusions = [];
                                this.specializations = [];
                            });
                        }
                        break;
                    case 'elleventh':
                        let app_result_item_id = parseInt(this.$route.params.id);
                        if (app_result_item_id && !this.loadingData) {
                            this.loadingData = true;
                            this.getConclusions({application_result_item_id: app_result_item_id}).then((res) => {
                                if (res.conclusions) {
                                    this.loadingData = false;
                                    this.conclusions = res.conclusions;
                                }
                                this.data = [];
                                this.app_information = {};
                                this.clinic = {};
                                this.address = {};
                                this.techniques = [];
                                this.equipments = [];
                                this.documents = [];
                                this.specializations = [];
                            }).catch((err) => {
                                this.loadingData = false; 
                                this.$alert(err.response.data);
                            });
                        }
                        break;
                    default:
                        break;
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        ...mapActions({
            getApplicationResults: 'submitApplications/getApplicationResults',
            getTechniquesForApplicationResult: 'medicalTechniques/getTechniquesForApplicationResult',
            getEquipmentsForApplicationResult: 'medicalEquipments/getEquipmentsForApplicationResult',
            getAllDocumentsOfClinic: 'clinics/getAllDocumentsOfClinic',
            getConclusions: 'checkApplicationResult/index',
            getSpecializations: 'submitApplications/getSpecializations'
        }),
        fetchData(){
            this.query = {application_id: parseInt(this.$route.params.id), application_result_id: parseInt(this.$route.params.application_result_id), name: this.activeName};
            if (!this.loadingData) {
                this.loadingData = true;
                this.getApplicationResults(this.query).then((res) => {
                    this.loadingData = false;
                    if (res.application_result) {
                        this.clinic_id = res.application_result.parent ? res.application_result.parent.clinic.id : null;
                        this.clinic_name = res.application_result.parent ? res.application_result.parent.clinic.legal_entity_name : '';

                        this.app_information = {};
                        this.app_information['application_number'] = res.application_result.application_number;
                        this.app_information['application_type'] = res.application_result.parent.application_type;
                        this.app_information['created_at'] = res.application_result.created_at;
                        this.app_information['last_created_at'] = res.application_result.created_at;
                        this.app_information['document_type'] = 'Litsenziya';
                        this.app_information['cabinet_type'] = res.application_result.parent.cabinet_type;
                        this.app_information['condition_id'] = res.application_result.condition_id;
                        this.app_information['items'] = Object.values(res.application_result.parent.clinic.building_informations_of_clinics).map(item => {
                            return {
                                cadastre_number: item.cadastre_number,
                                property_ownership: item.property_ownership,
                                document_name: item.document_name,
                                document_number: item.document_number,
                                document_date: item.document_date
                            };
                        });

                        this.data = [];
                        if (res.application_result.item_data) {
                            this.data.push(res.application_result.item_data.data);
                        }

                        if (res.application_result.parent) {
                            this.clinic = res.application_result.parent.clinic;
                            this.address = {};
                            this.address['region'] = res.application_result.parent.clinic.work_region;
                            this.address['city_district'] = res.application_result.parent.clinic.work_city_district;
                            this.address['address'] = res.application_result.parent.clinic.work_address;
                            this.address['time_of_registered'] = res.application_result.parent.clinic.time_of_registered;
                        }
                    }
                })
                .catch((err) => {
                    this.loadingData = false;
                    console.log(err);
                });
            }
        },
        showDrawer(){
            this.drawerCreate = true;
            this.selectedItem['id'] = this.$route.params.id;
        },
        drawerOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].closed()
            }
            // if (this.reloadList === true) {
            //     this.fetchData();
            //     this.afterFetchData();
            // }
            // if (_.isFunction(this.empty)) {
            //     this.empty()
            // }
        },
        closeDrawer(drawer){
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
    },
    // beforeRouteUpdate(to, from, next){
    //     console.log('worked');
    //     next();
    // },
    beforeRouteLeave (to, from, next) {
        this.clinic_name = '';
        this.data = [];
        this.app_information = {};
        this.clinic = {};
        this.address = {};
        this.techniques = [];
        this.equipments = [];
        this.documents = [];
        this.conclusions = [];
        this.specializations = [];
        next();
    },
};
</script>

